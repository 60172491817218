.App {
  width: 100%;
  height: 100%;
  font-size: 0.16rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    width: 100%;
    overflow: hidden;
    // height: 100%;
  }
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
:root:root {
  --adm-color-primary: #951a37;
}
.adm-radio {
  --icon-size: 0.2rem;
  --font-size: 0.16rem;
  --gap: 0.04rem;
}
.adm-input {
  --color: #626262;
  // --text-align: right;
  --placeholder-color: rgba(98, 98, 98, 0.5);
  .adm-input-element {
    width: calc(100% - 0.08rem);
    padding-right: 0.08rem;
  }
}
.adm-form {
  color: #626262;
  // --border-inner: 1px solid #b9b9b9;
  .adm-list-body {
    font-size: 0.16rem;
    .last-form-item {
      border-bottom: 1px solid #b9b9b9;
    }
    .adm-form-item-label {
      font-weight: bold;
    }
    .adm-list-item {
      border-top: 1px solid #b9b9b9;
    }
    .adm-list-item-content {
      border: none;
    }
  }
  .adm-radio-content {
    font-size: 0.16rem;
  }
  .adm-space-item {
    flex: 1;
  }
  .adm-space-horizontal > .adm-space-item {
    margin-right: 0;
  }
  .adm-list-item-content {
    padding-right: 0;
  }
}

.visitor-add-area {
  padding: 0.11rem 0.2rem 0.11rem;
  .visitor-add-btn {
    width: 1rem;
    margin-bottom: 0.16rem;
  }
  .adm-list-header {
    width: 100%;
    background-color: #ffc978;
    height: 0.36rem;
    line-height: 0.36rem;
    box-sizing: border-box;
    padding: 0 0.08rem 0 0.13rem;
    font-size: 0.16rem;
    color: #fff;
    font-weight: bold;
  }
  .adm-list-card {
    margin: 0 0 0.16rem;
    &:last-of-type {
      .adm-list-body {
        box-shadow: none;
        .adm-list-item {
          padding-left: 0rem;
        }
      }
    }
    .adm-list-header {
      border-radius: 0.04rem 0.04rem 0 0;
    }
    .adm-list-body {
      border: none;
      border-radius: 0 0 0.04rem 0.04rem;
      box-shadow: 0 0.05rem 0.06rem 0 #e8d8cf;

      .adm-list-item {
        &:last-of-type {
          border-bottom: none;
        }
      }
      .adm-list-item-content {
        // border: none;
        .adm-list-item-content-prefix {
          padding-top: 0.07rem;
          padding-bottom: 0.07rem;
        }
        .adm-list-item-content-main {
          padding: 0.07rem 0;
        }
      }
    }
  }
  .visitor-add-card {
    background-color: #e8d8cf;
    .adm-list-item {
      background-color: transparent;
    }
  }
}
